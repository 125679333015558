import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { getToken } from "../auth/auth";
import "react-toastify/dist/ReactToastify.css";
import "../styles/dashboard.css";

import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/navbar/Navbar";
import TemplateOption from "../components/templateOption/TemplateOption";
import key from "../clientVariables.json";
import HTMLPreview from "../components/htmlPreview/HTMLPreview";

function Send() {
  const [groups, setGroups] = useState([]);
  const [fromMail, setFromMail] = useState("");
  const [group, setGroup] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState(" ");
  const [template, setTemplate] = useState("none");

  useEffect(() => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };
    axios
      .get(`${key.domain}/api/v1/user/viewgroups`, config)
      .then((res) => {
        setGroups(res.data.groups);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = () => {
    const token = getToken();
    const config = {
      headers: { Authorization: token },
    };
    axios
      .post(
        `${key.domain}/api/v1/user/sendmail`,
        {
          fromMail,
          group,
          subject,
          message,
          template,
        },
        config
      )
      .then((res) => {
        toast.success("Successfully send mails", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => console.log(err));
  };

  const handleTemplateOption = (temp) => {
    setTemplate(temp);
  };

  return (
    <div>
      <div className="container">
        <Sidebar />
        <Navbar />
        <section className="home">
          <div style={{ marginTop: "100px" }}>
            <div className="mailer-container">
              <div className="email-card">
                <h2 className="email-card__title">Compose New Email</h2>
                <form className="email-card__form">
                  <div className="form-group">
                    <label for="emailFromt">From:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="emailFrom"
                      onChange={(e) => setFromMail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="emailTo">To:</label>
                    <select
                      className="form-control"
                      id="emailTo"
                      onChange={(e) => setGroup(e.target.value)}
                    >
                      <option>Select a group</option>
                      {groups.map((group) => (
                        <option id={group._id} value={group._id}>
                          {group.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label for="emailSubject">Subject:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="emailSubject"
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <TemplateOption
                      handleTemplateOption={handleTemplateOption}
                    />
                  </div>
                  <div className="email-card__actions">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => handleSubmit()}
                      // disabled={subject !== "" && group !== ""}
                    >
                      Send <i className="fa-sharp fa-solid fa-paper-plane" />
                    </button>
                  </div>
                </form>
              </div>
              <div className="template-option">
                {template && template?.content && (
                  <HTMLPreview html={template.content} />
                )}
              </div>
            </div>
            <ToastContainer
              position="bottom-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </div>
        </section>
      </div>
    </div>
  );
}

export default Send;
