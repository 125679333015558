import React from "react";
import PropTypes from "prop-types";

const HTMLPreview = ({ html }) => {
  return (
    <div
      style={{
        border: "1px solid #ccc",
        padding: "10px",
        backgroundColor: "#f9f9f9",
      }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

HTMLPreview.propTypes = {
  html: PropTypes.string.isRequired,
};

export default HTMLPreview;
