import React from "react";
import Register from "../components/register/Register";

function RegisterPage() {
  return (
    <div>
      <Register />
    </div>
  );
}

export default RegisterPage;
